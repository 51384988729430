
import PropTypes from "prop-types"
import React from "react"

import logo1 from "../../images/logo_basico.png"
import logo2 from "../../images/logo fesaem.png"

const Header = ({ siteTitle, source }) => (
      
      <header className="container-fluid block-header">        
        <div className="block-logos">
          <div>
            <img src={logo1} className="img-nodo" />
          </div>
          {
          source == 'fesaem' &&
          <div>
            <img src={logo2} className="img-logo" />
          </div>
          }
        </div>        
      </header>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
