/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"

import Header from "../header/header"
import Footer from "../footer/footer"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [utm_source, setUtm_source] = useQueryParam("utm_source", StringParam)  

  return (
    <div className="contenedor">
      <Header siteTitle={data.site.siteMetadata.title} source={utm_source}/>
      <div>
        <main>{children}</main>       
      </div>
      <Footer />    
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
