import React from "react"
import { Link } from "gatsby"

import logoMapa from "../../images/logo_mapa.png"
import logoCC from "../../images/logo_centro_comercial.png"
import logoFace from "../../images/logo_facebook.png"
import logoTwt from "../../images/logo_twitter.png"

const Footer = () => (

  <footer id="footer" className="container-fluid bg-foo pt-4">
    <div className="container container-foo justify-content-between align-items-start pt-5">
      <div className="d-flex ">
        <img src={logoMapa} className="img-mapa" style={{position: 'relative', top: '-44px'}} />
        <div className="pl-3" style={{maxWidth: '245px'}}>Alcance nacional, más de 1000 entidades y comercios nos han elegido</div>              
      </div>  
      <div className="d-flex pb-4">
        <img src={logoCC} className="img-centro" />
        <div className="pl-2" style={{maxWidth: '230px'}}>Contamos con el aval del Centro Comercial de Santa Fe</div>
      </div>  
      <div className="d-flex flex-lg-column mb-4 align-items-end align-items-lg-start">              
        <div className="pr-4 pr-md-0">
          <span style={{fontWeight: 600}}>CONTACTO</span><br />
          0342 455-5105 / 455-8578<br />
          <Link to="http://centrocomercialsf.com.ar" target="_blank" className="link-footer">http://centrocomercialsf.com.ar</Link><br />
        </div>              
        <div className="social pl-5 pl-lg-0 pb-2">
          <Link to="https://www.facebook.com/centrocomercialdesantafe" target="_blank"><img src={logoFace} className="true" /></Link>
          <Link to="https://twitter.com/infoCCSF" target="_blank"><img src={logoTwt} className="pl-3" /></Link>
        </div>
      </div>
    </div> 
  </footer>  
)

export default Footer;


